import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, UseMutationResult } from '@tanstack/react-query';

import ControlledTextInput from 'ui/components/inputs/controlled-text-input';

import { GetUserResponseDto, UpdateUserRequestDto } from 'permisos-common';

import { editUserSchema } from '../utils/validations/edit-user-schema';
import { IUser } from '../utils/interfaces/user.interface';
import { getUserRoles } from '../http/api/users';
import { TransferList } from './transferList';

interface IEditUserModalProps {
  isOpen: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
  selectedUser: IUser | null;
  editUserMutation: UseMutationResult<
    GetUserResponseDto,
    unknown,
    {
      username: string;
      userData: UpdateUserRequestDto;
    },
    unknown
  >;
}

interface IUserEditFormData {
  onbaseUsername?: string;
  osfUsername?: string;
}

export const EditUserModal = (props: IEditUserModalProps) => {
  const { isOpen, toggleOpen, selectedUser, editUserMutation } = props;

  const [right, setRight] = useState<readonly string[]>([]);
  const [left, setLeft] = useState<readonly string[]>([]);
  const [checked, setChecked] = useState<readonly string[]>([]);
  const [rightInitialValue, setRightInitialValue] = useState<string[]>([]);

  const { data: userRoles, isLoading: isGetUserRolesLoading } = useQuery({
    queryKey: ['getUserRoles', selectedUser?.username],
    queryFn: getUserRoles,
  });

  const {
    control: editUserFormControl,
    formState: {
      errors: editUserFormErrors,
      dirtyFields: editUserFormDirtyFields,
      isValid: editUserFormIsValid,
    },
    handleSubmit: handleEditUserFormSubmit,
    reset: editUserFormReset,
  } = useForm<IUserEditFormData>({
    mode: 'all',
    resolver: yupResolver(editUserSchema),
  });

  useEffect(() => {
    if (selectedUser) {
      editUserFormReset({
        onbaseUsername: selectedUser.onbaseUsername ?? '',
        osfUsername: selectedUser.osfUsername ?? '',
      });
    }
  }, [selectedUser, editUserFormReset]);

  useEffect(() => {
    if (userRoles) {
      setLeft(
        userRoles.allRoles.filter(
          (item: string) => !userRoles.userRoles.includes(item)
        )
      );
      setRight([...userRoles.userRoles]);
      setRightInitialValue([...userRoles.userRoles]);
    }
  }, [userRoles]);

  const onCloseEditUserDialog = () => {
    toggleOpen(false);
    editUserFormReset();
    if (userRoles) {
      setLeft(
        userRoles.allRoles.filter(
          (item: string) => !userRoles.userRoles.includes(item)
        )
      );
      setRight([...userRoles.userRoles]);
      setChecked([]);
    }
  };

  const onEditUserFormSubmit = async (data: IUserEditFormData) => {
    if (selectedUser) {
      const userData = data;
      editUserMutation.mutate({
        username: selectedUser.username,
        userData: {
          ...userData,
          userRoles: [...right],
        } as UpdateUserRequestDto,
      });
      onCloseEditUserDialog();
    }
  };

  const onEditUserFormReset = () => {
    editUserFormReset();
    if (userRoles) {
      setLeft(
        userRoles.allRoles.filter(
          (item: string) => !userRoles.userRoles.includes(item)
        )
      );
      setRight([...userRoles.userRoles]);
      setChecked([]);
    }
  };

  const disabledCondition = useCallback(() => {
    return (
      !editUserFormIsValid ||
      (!editUserFormDirtyFields.osfUsername &&
        !editUserFormDirtyFields.onbaseUsername &&
        JSON.stringify([...rightInitialValue].sort()) ===
          JSON.stringify([...right].sort()))
    );
  }, [
    editUserFormDirtyFields.onbaseUsername,
    editUserFormDirtyFields.osfUsername,
    editUserFormIsValid,
    right,
    rightInitialValue,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseEditUserDialog}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Editar Usuario
        <IconButton
          aria-label="close"
          onClick={onCloseEditUserDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleEditUserFormSubmit(onEditUserFormSubmit)}>
        <DialogContent>
          <Grid
            container
            sx={{
              padding: 1,
            }}
            rowSpacing={4}
            columnSpacing={2}
          >
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                label="Nombre de usuario"
                defaultValue={selectedUser?.username ?? ''}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextInput
                label="Usuario de OSF"
                name="osfUsername"
                control={editUserFormControl}
                type="text"
                errors={editUserFormErrors.osfUsername}
                isDirty={Boolean(editUserFormDirtyFields?.osfUsername)}
                inputsProps={{
                  sx: {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextInput
                label="Usuario de onBase"
                name="onbaseUsername"
                control={editUserFormControl}
                type="text"
                errors={editUserFormErrors.onbaseUsername}
                isDirty={Boolean(editUserFormDirtyFields?.onbaseUsername)}
                inputsProps={{
                  sx: {
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 500,
                }}
                variant="h6"
                component="h3"
              >
                Roles
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isGetUserRolesLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <TransferList
                  right={right}
                  setRight={setRight}
                  left={left}
                  setLeft={setLeft}
                  checked={checked}
                  setChecked={setChecked}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onEditUserFormReset}>Restablecer</Button>
          <Button
            variant="contained"
            disabled={disabledCondition()}
            onClick={handleEditUserFormSubmit(onEditUserFormSubmit)}
            sx={{
              marginLeft: 2,
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
