import * as yup from 'yup';

export const editUserSchema = yup.object({
  onbaseUsername: yup
    .string()
    .typeError('Debe ser un string')
    .test(
      'len',
      'No puede contener espacios en blanco',
      (value) => !value || value.trim() !== ''
    ),
  osfUsername: yup
    .string()
    .typeError('Debe ser un string')
    .test(
      'len',
      'No puede contener espacios en blanco',
      (value) => !value || value.trim() !== ''
    ),
});
