import { forwardRef } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
interface IFormSubmitSnackbarProps {
  isOpen: boolean;
  onClose: () => void;
  severity?: AlertColor;
  text: string;
}

export const FormSubmitSnackbar = (props: IFormSubmitSnackbarProps) => {
  const { isOpen, onClose, severity, text } = props;

  const FormSubmitAlert = forwardRef<HTMLDivElement, AlertProps>(
    (_props, ref) => {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {..._props} />;
    }
  );
  FormSubmitAlert.displayName = 'FormSubmitAlert';

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <FormSubmitAlert
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {text}
      </FormSubmitAlert>
    </Snackbar>
  );
};
