import EditIcon from '@mui/icons-material/Edit';
import { DataTable, DataTableFilterEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';

import { IUser } from '../utils/interfaces/user.interface';
import useUser from '../auth/useUser';

interface IUsersDataTableProps {
  users: IUser[];
  onEditUser: (user: IUser) => void;
  onFilter: (e: DataTableFilterEvent) => void;
  onSort: (e: DataTableFilterEvent) => void;
  selectedFilter: DataTableFilterEvent;
}

export const UsersDataTable = (props: IUsersDataTableProps) => {
  const { users, onEditUser, onFilter, onSort, selectedFilter } = props;

  const currentUser = useUser();

  const hasEditUserPermission = currentUser?.roles.some(
    (permission) => permission === 'permisos/update_user'
  );
  const hasGetUserRolesPermission = currentUser?.roles.some(
    (permission) => permission === 'permisos/get_user_roles'
  );

  const formatLastLoginBody = (rowData: IUser) => {
    const dateTime = DateTime.fromJSDate(new Date(rowData.lastLogin)).setZone(
      'America/Bogota'
    );
    const formattedDateTime = dateTime.toLocaleString(
      DateTime.DATETIME_MED_WITH_SECONDS
    );

    return formattedDateTime;
  };

  const formatOsfUsernameBody = (rowData: IUser) => {
    if (!rowData.osfUsername || rowData.osfUsername === '') {
      return '--';
    }

    return rowData.osfUsername;
  };

  const formatOnBaseUsernameBody = (rowData: IUser) => {
    if (!rowData.onbaseUsername || rowData.onbaseUsername === '') {
      return '--';
    }

    return rowData.onbaseUsername;
  };

  return (
    <div className="card">
      <DataTable
        value={users ?? []}
        tableStyle={{ minWidth: '50rem' }}
        paginator
        rows={50}
        rowsPerPageOptions={[5, 10, 25, 50]}
        emptyMessage="No hay datos"
        removableSort
        onSort={onSort}
        sortField={selectedFilter.sortField}
        sortOrder={selectedFilter.sortOrder}
        onFilter={onFilter}
        filters={selectedFilter.filters}
      >
        <Column
          field="username"
          header="Nombre de usuario"
          sortable
          filter
          filterPlaceholder="Buscar por nombre de usuario"
        ></Column>
        <Column
          header="Nombre de usuario de OSF"
          body={formatOsfUsernameBody}
        ></Column>
        <Column
          header="Nombre de usuario OnBase"
          body={formatOnBaseUsernameBody}
        ></Column>
        <Column
          field="lastLogin"
          header="Último ingreso"
          sortable
          body={formatLastLoginBody}
        ></Column>
        {hasEditUserPermission && hasGetUserRolesPermission && (
          <Column
            header="Acciones"
            body={(rowData: IUser) => (
              <EditIcon
                color="info"
                sx={{ cursor: 'pointer' }}
                onClick={() => onEditUser(rowData)}
              />
            )}
          ></Column>
        )}
      </DataTable>
    </div>
  );
};
