import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { NextPage } from 'next';
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { SortOrder } from 'primereact/api';
import { DataTableFilterEvent } from 'primereact/datatable';

import { Policy, Forbidden } from 'ui';
import theme from 'ui/styles/theme';

import useUser from '../auth/useUser';
import { getUsers } from '../http/api/users';
import { EditUserModal } from '../components/editUserModal';
import { updateUser } from '../http/api/users';
import { IUser } from '../utils/interfaces/user.interface';
import { UsersDataTable } from '../components/usersDataTable';
import { FormSubmitSnackbar } from '../components/formSubmitSnackbar';

const UsersPage: NextPage = () => {
  const currentUser = useUser();

  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [openEditUserFormSubmitAlert, setOpenEditUserFormSubmitAlert] =
    useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DataTableFilterEvent>({
    filters: {
      username: { value: '', matchMode: 'contains' },
    },
    sortField: 'username',
    sortOrder: SortOrder.ASC,
  });

  const onFilter = (e: DataTableFilterEvent) => {
    setSelectedFilter(e);
  };

  const onSort = (e: DataTableFilterEvent) => {
    setSelectedFilter(e);
  };

  const queryClient = useQueryClient();

  const { data: users, isLoading: isGetUsersLoading } = useQuery({
    queryKey: ['getUsers'],
    queryFn: getUsers,
  });

  const onEditUser = (user: IUser) => {
    setIsEditUserDialogOpen(true);
    setSelectedUser(user);
  };

  const editUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      queryClient.invalidateQueries({ queryKey: ['getUserRoles'] });
      setOpenEditUserFormSubmitAlert(true);
    },
  });

  return (
    <Policy
      user={currentUser}
      roles={['get_users']}
      fallback={
        <Forbidden
          text={'No tienes permiso para acceder a la aplicación'}
          userLogout={currentUser?.actions?.logout}
        />
      }
    >
      <Grid
        container
        sx={{
          background: theme.palette.background.paper,
          boxShadow: theme.shadows[4],
          borderRadius: 1,
          marginTop: 2,
          padding: 2,
        }}
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 500,
            }}
            variant="h5"
            component="h2"
          >
            Usuarios
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isGetUsersLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <UsersDataTable
              users={users as IUser[]}
              onEditUser={onEditUser}
              onFilter={onFilter}
              onSort={onSort}
              selectedFilter={selectedFilter}
            />
          )}
        </Grid>
      </Grid>
      <EditUserModal
        isOpen={isEditUserDialogOpen}
        toggleOpen={setIsEditUserDialogOpen}
        selectedUser={selectedUser}
        editUserMutation={editUserMutation}
      />
      <FormSubmitSnackbar
        isOpen={openEditUserFormSubmitAlert}
        onClose={() => setOpenEditUserFormSubmitAlert(false)}
        severity="success"
        text={'¡Usuario editado exitosamente!'}
      />
    </Policy>
  );
};

export default UsersPage;
