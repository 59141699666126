import { QueryFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { GetUserResponseDto, GetUserRolesResponseDto, UpdateUserRequestDto } from 'permisos-common';

import api from '../api';

export const getUsers: QueryFunction<GetUserResponseDto[]> = async ({ queryKey }) => {
  return api.get(`/users`).then(r => r.data);
}

export const updateUser = async ({
  username,
  userData,
}: {
  username: string;
  userData: UpdateUserRequestDto;
}) => {
  return api.put<UpdateUserRequestDto, AxiosResponse<GetUserResponseDto>>(`/users/${username}`, userData).then(r => r.data);
};

export const getUserRoles: QueryFunction<GetUserRolesResponseDto> = async ({ queryKey }) => {
  const [, username] = queryKey;
  if (!username) {
    return null;
  }

  return api.get(`/users/${username}/roles`).then(r => r.data);
};
